import React from "react"
import { Fade } from "react-awesome-reveal";
import { GatsbyImage } from "gatsby-plugin-image";

const SchoolGallery = ({ galleryImageOne }) => (
  <div className="school-gallery">
    <div className="row">
      <div className="col">
        <Fade triggerOnce>
          <div className="image-wrapper">
            <GatsbyImage image={galleryImageOne} />
          </div>
        </Fade>
      </div>
      <div className="col" />
    </div>
  </div>
)

export default SchoolGallery
