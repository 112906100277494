import React from "react"
import { GatsbyImage } from "gatsby-plugin-image";

const SchoolBanner = ({ image }) => (
  <section className="banner school-banner">
    <GatsbyImage image={image} />
    <div className="banner-overlay-vertical" />
    <div className="banner-content" />
  </section>
)

export default SchoolBanner
